import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from 'react'
import { Navbar, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarBrand, Dropdown, Collapse } from 'reactstrap'

export default function TopNavbar() {

    // for dropdown
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    // for product collapse toggleProductCollapse
    const [productCollapse, setProductCollapse] = useState(false);
    const toggleProductCollapse = () => {
        setProductCollapse(!productCollapse);
        setDropdownOpen(prevState => prevState);
    }

    // for language collapse
    const [languageCollapse, setLanguageCollapse] = useState(false);
    const toggleLanguageCollapse = () => {
        setLanguageCollapse(!languageCollapse);
        setDropdownOpen(prevState => prevState);
    }

    // for burger menu icon
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div>
            <Navbar light expand="md">
                <div>
                    {/* <Nav navbar>
                        <NavbarBrand>
                            <NavLink> */}
                                {/* <Link to="/"> */}
                                    <StaticImage 
                                        src="../images/makers.png" 
                                        alt="TopNavBar Maker Logo"
                                        width={150}
                                        height={70}
                                         />
                                {/* </Link> */}
                            {/* </NavLink>
                        </NavbarBrand>
                    </Nav> */}
                </div>
                <div>
                    <Nav className="mr-auto" navbar>
                        <NavItem className="d-md-block d-none">
                            <NavLink className="navbar-link">
                                <Link to="/">Home</Link>
                            </NavLink>
                        </NavItem>
                        <NavItem className="d-md-block d-none">
                            <UncontrolledDropdown nav inNavbar className="navbar-dropdown">
                                <DropdownToggle nav caret>
                                    Products
                                </DropdownToggle>
                                <DropdownMenu center>
                                    <DropdownItem className="sitemap-link">
                                        <Link to="/products-curry">Curry</Link>
                                    </DropdownItem>
                                    <DropdownItem className="sitemap-link">
                                    <   Link to="/products-tumeric-chili">Tumeric & Chili</Link>
                                    </DropdownItem>
                                    <DropdownItem className="sitemap-link">
                                        <Link to="/products-soup">Soup</Link>
                                    </DropdownItem>
                                    <DropdownItem className="sitemap-link">
                                        <Link to="/products-korma">Korma</Link>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </NavItem>
                        {/* <NavItem className="d-md-block d-none">
                            <NavLink className="navbar-link">
                                <Link to="/recipe-list">Recipe</Link>
                            </NavLink>
                        </NavItem> */}
                        <NavItem className="d-md-block d-sm-none d-none">
                            <NavLink className="navbar-link">
                                <Link to="/about">About Us</Link>
                            </NavLink>
                        </NavItem>
                        <NavItem className="d-md-block d-none">
                            <NavLink className="navbar-link">
                                <Link to='/contact'>Contact Us</Link>
                            </NavLink>
                        </NavItem>
                        {/* <NavItem className="d-md-block d-none">
                            <UncontrolledDropdown nav inNavbar className="navbar-dropdown">
                                <DropdownToggle nav caret>
                                    Language
                                </DropdownToggle>
                                <DropdownMenu center>
                                    <DropdownItem>
                                        English
                                    </DropdownItem>
                                    <DropdownItem>
                                        Malay
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </NavItem> */}

                        {/* burger menu for mobile */}
                        <div>
                            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="d-lg-none d-md-none">
                                <DropdownToggle className="burgerMenuButton"
                                    tag="span"
                                    data-toggle="dropdown"
                                    aria-expanded={!dropdownOpen}
                                >
                                    <div className={isOpen ? "burgerMenuContainer change" : "burgerMenuContainer"} onClick={() => setIsOpen(!isOpen)}>
                                        <div className="bar1"></div>
                                        <div className="bar2"></div>
                                        <div className="bar3"></div>
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu className="burgerMenuItemContainer">
                                    {/* <div className="burgerMenuItem">
                                        <a className="productCollapseToggler" onClick={toggleProductCollapse}>Products</a>
                                        <Collapse isOpen={productCollapse} className="productCollapseItems">
                                            <div><Link to="/products-curry">Curry</Link></div>
                                            <div><Link to="/products-tumeric-chili">Tumeric & Chili</Link></div>
                                            <div><Link to="/products-soup">Soup</Link></div>
                                            <div><Link to="/products-korma">Korma</Link></div>
                                        </Collapse>
                                    </div> */}
                                     <div className="burgerMenuItem">
                                        <Link to="/">Home</Link>
                                    </div>

                                    <div className="burgerMenuItem"><Link to="/products-curry">Curry</Link></div>
                                    <div className="burgerMenuItem"><Link to="/products-tumeric-chili">Tumeric & Chili</Link></div>
                                    <div className="burgerMenuItem"><Link to="/products-soup">Soup</Link></div>
                                    <div className="burgerMenuItem"><Link to="/products-korma">Korma</Link></div>

                                    {/* <div className="burgerMenuItem">
                                        <Link to="/recipe-list">Recipe</Link>
                                    </div> */}
                                    <div className="burgerMenuItem">
                                        <Link to="/about">About Us</Link>
                                    </div>
                                    <div className="burgerMenuItem">
                                        <Link to='/contact'>Contact Us</Link>
                                    </div>
                                    {/* <div className="burgerMenuItem">
                                        <a className="languageCollapseToggler" onClick={toggleLanguageCollapse}>Language</a>
                                        <Collapse isOpen={languageCollapse} className="productCollapseItems">
                                            <p>English</p>
                                            <p>Malay</p>
                                        </Collapse>
                                    </div> */}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </Nav>
                </div>
            </Navbar>
        </div>
    )
}

