import React from 'react'
import TopNavbar from './TopNavbar'
import '../styles/global.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFacebook , faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Container, Row, Col} from 'reactstrap'

export default function Layout({ children }) {

    // get current year
    const d =  new Date();
    let year = d.getFullYear();
    
    return (
        <div className="layout">
            <TopNavbar />
            <div className="content">
                { children }
            </div>
            <footer>
                <Container>
                    <Row>
                        <Col md="6">
                            <p>© {year} Maker Recipe - All Rights Reserved.</p>
                        </Col>
                        <Col md="6" className="footerIcon-column">
                            <div className="footerIcon-container">
                                <div>
                                    <a href="https://www.facebook.com/MakerRecipe">
                                        <FontAwesomeIcon icon={faFacebook} className="footerIcon" />
                                    </a>
                                </div>
                             <div>
                                    <a href="https://www.instagram.com/karicap_o/">
                                        <FontAwesomeIcon icon={faInstagram} className="footerIcon" />
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </div>
    )
}





